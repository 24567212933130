var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('Breadcrumbs',{attrs:{"routes":[{ name: 'Inicio' }, {name: 'Producto' }, { name: 'Item' }, { name: 'Crear Item' }]}}),_c('v-card',{staticStyle:{"border-radius":"10px 10px 0 0"}},[_c('v-toolbar',{staticStyle:{"border-radius":"10px 10px 0 0"},attrs:{"card":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(this.currentProducto.nombre))])],1),_c('br'),_c('v-toolbar',{attrs:{"color":"grey darken-1","dark":"","card":""}},[_c('v-toolbar-title',[_vm._v("Crear Item")]),_c('v-spacer')],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitImagen.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Nombre","outline":"","rules":_vm.rules.nombre,"error":!!_vm.formErrors.nombre,"error-messages":_vm.formErrors.nombre},on:{"keyup":function () {
                  _vm.formErrors.nombre = undefined;
                  delete _vm.formErrors.nombre;
                }},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}})],1),_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"SKU","outline":"","rules":_vm.rules.SKU,"error":!!_vm.formErrors.SKU,"error-messages":_vm.formErrors.SKU},on:{"keyup":function () {
                  _vm.formErrors.SKU = undefined;
                  delete _vm.formErrors.SKU;
                }},model:{value:(_vm.form.SKU),callback:function ($$v) {_vm.$set(_vm.form, "SKU", $$v)},expression:"form.SKU"}})],1),_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Costo","outline":"","rules":_vm.rules.costo,"error":!!_vm.formErrors.costo,"error-messages":_vm.formErrors.costo},on:{"keyup":function () {
                  _vm.formErrors.costo = undefined;
                  delete _vm.formErrors.costo;
                }},model:{value:(_vm.form.costo),callback:function ($$v) {_vm.$set(_vm.form, "costo", $$v)},expression:"form.costo"}})],1),(this.currentProducto.tipo === 'color')?_c('v-flex',{attrs:{"md6":"","sm12":"","xs12":""}},[_c('div',{staticStyle:{"width":"100%","height":"60px","border":"2px solid rgba(0,0,0,0.54)","border-radius":"4px"}},[_c('label',[_vm._v("Selecciona el color")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.color),expression:"form.color"}],staticClass:"input__color",attrs:{"type":"color"},domProps:{"value":(_vm.form.color)},on:{"change":_vm.codigoColor,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "color", $event.target.value)}}})])]):_vm._e(),(this.currentProducto.tipo === 'color')?_c('v-flex',{attrs:{"md6":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Color codigo","outline":"","rules":_vm.rules.color_codigo,"error":!!_vm.formErrors.color_codigo,"error-messages":_vm.formErrors.color_codigo},on:{"keyup":function () {
                  _vm.formErrors.color_codigo = undefined;
                  delete _vm.formErrors.color_codigo;
                }},model:{value:(_vm.form.color_codigo),callback:function ($$v) {_vm.$set(_vm.form, "color_codigo", $$v)},expression:"form.color_codigo"}})],1):_vm._e(),_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Stock minimo","outline":"","rules":_vm.rules.stock_minimo,"error":!!_vm.formErrors.stock_minimo,"error-messages":_vm.formErrors.stock_minimo},on:{"keyup":function () {
                  _vm.formErrors.stock_minimo = undefined;
                  delete _vm.formErrors.stock_minimo;
                }},model:{value:(_vm.form.stock_minimo),callback:function ($$v) {_vm.$set(_vm.form, "stock_minimo", $$v)},expression:"form.stock_minimo"}})],1),(this.currentProducto.tipo === 'talla')?_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Talla","outline":"","rules":_vm.rules.talla,"error":!!_vm.formErrors.talla,"error-messages":_vm.formErrors.talla},on:{"keyup":function () {
                  _vm.formErrors.talla = undefined;
                  delete _vm.formErrors.talla;
                }},model:{value:(_vm.form.talla),callback:function ($$v) {_vm.$set(_vm.form, "talla", $$v)},expression:"form.talla"}})],1):_vm._e(),_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.estados,"dense":"","outline":"","clearable":"","small-chips":"","label":"Seleccionar Estado","item-text":"nombre","item-value":"id","error":!!_vm.formErrors.estado,"error-messages":_vm.formErrors.estado},on:{"change":function () {
                  _vm.formErrors.estado = undefined;
                  delete _vm.formErrors.estado;
                }},model:{value:(_vm.form.estado),callback:function ($$v) {_vm.$set(_vm.form, "estado", $$v)},expression:"form.estado"}})],1)],1)],1),_c('center',[_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":!_vm.validForm || _vm.processingForm,"loading":_vm.processingForm}},[_vm._v("Guardar")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Volver ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }