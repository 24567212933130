<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, {name: 'Producto' }, { name: 'Item' }, { name: 'Crear Item' }]" />
    <v-card style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title>{{ this.currentProducto.nombre }}</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Crear Item</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitImagen">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.nombre"
                :disabled="processingForm"
                label="Nombre"
                outline
                :rules="rules.nombre"
                :error="!!formErrors.nombre"
                :error-messages="formErrors.nombre"
                @keyup="
                  () => {
                    formErrors.nombre = undefined;
                    delete formErrors.nombre;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.SKU"
                :disabled="processingForm"
                label="SKU"
                outline
                :rules="rules.SKU"
                :error="!!formErrors.SKU"
                :error-messages="formErrors.SKU"
                @keyup="
                  () => {
                    formErrors.SKU = undefined;
                    delete formErrors.SKU;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.costo"
                :disabled="processingForm"
                label="Costo"
                outline
                :rules="rules.costo"
                :error="!!formErrors.costo"
                :error-messages="formErrors.costo"
                @keyup="
                  () => {
                    formErrors.costo = undefined;
                    delete formErrors.costo;
                  }
                "
              />
            </v-flex>
            <v-flex md6 sm12 xs12 v-if="this.currentProducto.tipo === 'color'">
              <div
                style=" width: 100%; height: 60px; border: 2px solid rgba(0,0,0,0.54); border-radius: 4px;"
              >
                <label>Selecciona el color</label>
                <br />
                <input
                  type="color"
                  v-model="form.color"
                  class="input__color"
                  @change="codigoColor"
                />
              </div>
            </v-flex>
            <v-flex md6 sm12 xs12 v-if="this.currentProducto.tipo === 'color'">
              <v-text-field
                v-model="form.color_codigo"
                :disabled="processingForm"
                label="Color codigo"
                outline
                :rules="rules.color_codigo"
                :error="!!formErrors.color_codigo"
                :error-messages="formErrors.color_codigo"
                @keyup="
                  () => {
                    formErrors.color_codigo = undefined;
                    delete formErrors.color_codigo;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.stock_minimo"
                :disabled="processingForm"
                label="Stock minimo"
                outline
                :rules="rules.stock_minimo"
                :error="!!formErrors.stock_minimo"
                :error-messages="formErrors.stock_minimo"
                @keyup="
                  () => {
                    formErrors.stock_minimo = undefined;
                    delete formErrors.stock_minimo;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12 v-if="this.currentProducto.tipo === 'talla'">
              <v-text-field
                v-model="form.talla"
                :disabled="processingForm"
                label="Talla"
                outline
                :rules="rules.talla"
                :error="!!formErrors.talla"
                :error-messages="formErrors.talla"
                @keyup="
                  () => {
                    formErrors.talla = undefined;
                    delete formErrors.talla;
                  }
                "
              />
            </v-flex>
            <!-- <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.formato_venta"
                :items="tipos"
                dense
                outline
                clearable
                small-chips
                label="Formato de Venta"
                :error="!!formErrors.formato_venta"
                :error-messages="formErrors.formato_venta"
                @change="
                  () => {
                    formErrors.formato_venta = undefined;
                    delete formErrors.formato_venta;
                  }
                "
              />
            </v-flex> -->
            <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.estado"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                label="Seleccionar Estado"
                item-text="nombre"
                item-value="id"
                :error="!!formErrors.estado"
                :error-messages="formErrors.estado"
                @change="
                  () => {
                    formErrors.estado = undefined;
                    delete formErrors.estado;
                  }
                "
              />
            </v-flex>
          </v-layout>
        </v-container>
        <center>
          <v-btn
            type="submit"
            color="success"
            :disabled="!validForm || processingForm"
            :loading="processingForm"
            >Guardar</v-btn
          >
            <v-btn
            color="error"
            @click="$router.go(-1)"
            
          >
            Volver
          </v-btn>
        </center>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Crear Imagen Producto" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      formErrors: [],
      activeBtn: 2,
      tipo: "",
      form: {
        nombre: "",
        formato_venta: "UN",
        SKU: "",
        costo: "",
        color: "",
        color_codigo: "",
        stock_minimo: "",
        talla: "",
        estado: true
      },
      estados: [
        { id: false, nombre: "inactivo" },
        { id: true, nombre: "activo" }
      ],
      tipos: ["UN", "SACO"],
      validForm: true,
      processingForm: false,
      rules: {
        formato_venta: [v => !!v || "El formato de venta es requerido"]
      }
    };
  },
  computed: {
    ...mapState({
      currentProducto: state => state.productos.currentProducto
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getProducto({ productoId: this.$route.params.id });    
  },
  methods: {
    ...mapActions({
      postItems: "productos/postItems",
      getProducto: "productos/getProducto"
    }),
    codigoColor() {
      this.form.color_codigo = this.form.color;
    },
    submitImagen() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.postItems({ productoId: this.$route.params.id, data: this.form })
        .then(response => {
          this.processingForm = false;
          this.$router.push({ name: "ListadoProductItems", params: { id: this.$route.params.id } });
        })
        .catch(error => {
          this.processingForm = false;
          this.formErrors = error.response.data.errors || {};
        });
    }
  }
};
</script>
<style>
.input__color {
  width: 200px;
  height: 40px;
}
.select__color  {
  width: 100%;
  height: 60px;
  border: 2px solid rgba(0, 0, 0, 0.54);
}
</style>
